import React from 'react';

import CustomLayout from '../components/CustomLayout';
import CenteredItem from '../components/common/CenteredItem';

const Custom404 = (props) => {
    return (
        <CustomLayout>
            <CenteredItem
                header={
                    <span style={{textAlign: 'center'}}>
                        404 | You took a wrong turn... 
                        <span style={{whiteSpace: 'nowrap', textAlign: 'center'}}>¯\_(ツ)_/¯</span>
                    </span>
                }
            />
        </CustomLayout>
    );
}

export default Custom404
